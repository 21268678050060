export const Routes = {
  HOME: '/',
  AUTH_SIGNIN: 'auth/signin',
  AUTH_CALLBACK: 'auth/callback',
  ETF_RANKINGS: 'rankings/etfs',
  STOCK_RANKINGS: 'rankings/stocks',
  CANNABIS_STOCK_RANKINGS: 'rankings/stocks/cannabis',
  FAQ: 'faq',
  ABOUT: 'about',
  CONTACT: 'contact',
  PRICING: 'pricing',
  PURCHASE: 'purchase',
  PURCHASE_PLAN_VIP: 'purchase/vip',
  PURCHASE_PLAN_STANDARD: 'purchase/standard',
  DASHBOARD: 'dashboard',
  LEGAL_TERMS_OF_SERVICE: 'legal/terms-of-service',
  LEGAL_PRIVACY_POLICY: 'legal/privacy-policy',
  SETTINGS_GENERAL: 'settings/general',
  SETTINGS_BILLING: 'settings/billing'
} as const;

export type Route = (typeof Routes)[keyof typeof Routes];
export const ROUTES: string[] = Object.values(Routes);

export function getFinalPath(route: Route) {
  const index = route.lastIndexOf('/');
  if (index === -1) {
    return route;
  }
  return route.slice(index + 1);
}

export function routeToUrl(route: Route) {
  if (route === Routes.HOME) {
    return route;
  }
  return `/${route}`;
}
